/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaHandPointRight, FaRegClock } from "react-icons/fa6";
import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Flag1 from "../../assets/images/eng.png";
import Flag2 from "../../assets/images/russia.png";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../base-components/Loader/Loader";
import { FatchApi } from "../../utils/FatchApi";
import useToast from "../../hooks/useToast";
import { GetEachCourse } from "../../lib/Redux/slices/EachCourse";
import { IsNext_Reducer } from "../../lib/Redux/slices/ExtraSlice";

function OnBoard() {
  const EachCourse = useSelector((state) => state.EachCourse);
  const state = useSelector((state) => state);
  // console.log(state, "each course");
  const { isAuthenticated } = useSelector((state) => state.user);
  const CourseIdR = useSelector((state) => state.CourseId);
  console.log(CourseIdR, "c");
  const SentanceFilter = useSelector((state) => state.SentanceFilter);
  const IsDialect = useSelector((state) => state.IsDialect.type);

  const location = useLocation();

  // console.log(SentanceFilter?.data[0]?.radio?Only new, "sskkn");

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [courseId, setCourseId] = useState(location?.state?.courseId);
  const [sentanceHistory, setSentanceHistory] = useState();
  const [LoadSen, setLoadSen] = useState(false);
  const [scheduleCount, setScheduleCount] = useState();

  // console.log(EachCourse?.data,'courseiiii');

  const [selected, setSelected] = useState([]);

  // console.log(EachCourse?.data, "each course");

  const EachCourseSentance = EachCourse?.data?.filter((item) => {
    return item?.isFinalCheck == true && item?.isLearner == true;
  });

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const do_save_langs = async (val) => {
    const isValueInArray = selected.includes(val);
    if (isValueInArray) {
      const newArray = selected.filter((item) => item !== val);
      setSelected(newArray);
    } else {
      setSelected([...selected, val]);
    }
  };

  const handleSession = () => {
    if (isAuthenticated) {
      // console.log('its authanticated here',EachCourse && EachCourse?.data?.filter((data) =>data?.isLearner === true && data?.isFinalCheck === true).length);
      if (
        EachCourse &&
        EachCourse?.data?.filter(
          (data) => data?.isLearner === true && data?.isFinalCheck === true
        ).length >= 1
      ) {
        if (data && data[0]?.radio == "Only new") {
          navigate("/view/sentence", {
            state: {
              // v: EachCourse?.data[sentanceHistory?.sentenceRepetition],
              v: EachCourse?.data[0],
              // index: sentanceHistory?.uniqueNewSentence?.length,
              index: 0,
              alldata: EachCourseSentance && EachCourseSentance,
              // Id: EachCourse?.data[sentanceHistory?.sentenceRepetition]?._id,
              Id: EachCourse?.data[0]?._id,
              language: location?.state?.language,
            },
          });
        }
        if (data && data[0]?.radio == "Only repetition") {
          navigate("/view/sentence", {
            state: {
              v: EachCourse?.data[0],
              index: 0,
              alldata: EachCourseSentance && EachCourseSentance,
              Id: EachCourse?.data[0]?._id,
              language: location?.state?.language,
            },
          });
        }
        if (data && data[0]?.radio == "Only new and repetition") {
          navigate("/view/sentence", {
            state: {
              v: EachCourse?.data[sentanceHistory?.sentenceRepetition],
              index: sentanceHistory?.uniqueNewSentence?.length,
              alldata: EachCourseSentance && EachCourseSentance,
              Id: EachCourse?.data[sentanceHistory?.sentenceRepetition]?._id,
              language: location?.state?.language,
            },
          });
        }
      } else {
        showToast("No data found", "warning");
      }
    } else {
      navigate("/view/sentence", {
        state: {
          v: LocalSentance?.data?.sentenceIds.filter(
            (data) => data?.isFinalCheck === true && data?.isLearner === true
          )[0],
          index: 0,
          alldata: LocalSentance?.data?.sentenceIds.filter(
            (data) => data?.isFinalCheck === true && data?.isLearner === true
          ),
          Id: LocalSentance?.data?.sentenceIds.filter(
            (data) => data?.isFinalCheck === true && data?.isLearner === true
          )[0]?._id,
          language: location?.state?.language,
        },
      });
    }
    // navigate("/view/sentence",{state:})
  };

  useEffect(() => {
    dispatch(IsNext_Reducer(false));

    const Get = async () => {
      try {
        // setFilterLoad(true);
        const data = await FatchApi({
          // user: User.user,
          request: "get",
          data: "",
          Api: !IsDialect
            ? `/api/v1/learne-sentences-manage?courseId=${
                CourseIdR && CourseIdR?.data
              }`
            : `/api/v1/learne-sentences-dialect-manage?courseId=${
                CourseIdR && CourseIdR?.data
              }`,
        });

        // console.log(data, 'data inot ..');
        if (data) {
          // setFilterLoad(false);
          setSentanceHistory(data);
        }
      } catch (error) {
        // setFilterLoad(false);
        console.error("Error fetching data:", error);
        // Optionally, handle the error (e.g., show an error message to the user)
      }
    };

    Get();
  }, []);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.SentanceFilter);

  const LocalSentance = useSelector((state) => state.LocalSentance);

  const EachCourseSentanceLocal =
    LocalSentance?.data &&
    LocalSentance?.data?.sentenceIds?.filter((item) => {
      return item?.isFinalCheck == true && item?.isLearner == true;
    });

  useEffect(() => {
    const handleLanguage = async () => {
      try {
        setLoadSen(true);
        // setFilterLoad(true);

        // console.log(data[0].tags,'data of tags');

        const fillData = data && data[0] && data[0]?.tags?.filter((data)=>{
          return data !== 'slang'
        })

        console.log(fillData,'fill data');
        
        

        const obj = {
          tag :fillData?.length >= 1 ? fillData : ['classic','insult'] ,
          // tag : data[0].tags,
          manageType: data && data[0]?.radio,
          // courseId : location.state.courseId,
          courseId: CourseIdR?.data,
        };

        const langauge = await FatchApi({
          user: user,
          request: "post",
          data: obj,
          courseId: CourseIdR?.data,
          // Api: `/api/v1/get-learner-sentences?courseId=${id}`,
          Api: !IsDialect
            ? `/api/v1/manage-learner-filter`
            : "/api/v1/manage-learner-filter-dia",
        });

        if (langauge && langauge?.sentences?.length >= 1) {
          // console.log(location?.state?.language, "lan...", langauge, "ifff..........");
          setLoadSen(false);
          // console.log(langauge?.scheduleCount,'lang ....');
          setScheduleCount(langauge?.scheduleCount);
          dispatch(GetEachCourse(langauge.sentences));
          navigate("/course/details", { state: { language: true } });
        } else {
          setLoadSen(false);
          // console.log(location?.state?.language, "lnag", langauge, "ele.........");
          dispatch(GetEachCourse([]));
        }
      } catch (error) {
        setLoadSen(false);
        // setFilterLoad(false);
        console.error("Error fetching language data:", error);
        // Optionally, handle the error (e.g., show an error message to the user)
      }
    };

    handleLanguage();
  }, [data && data[0]]);

  const showToast = useToast();

  console.log(sentanceHistory?.uniqueNewSentence, "learner data");

  return (
    // EachCourse?.loading ? <Loader/> :

    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="langugae_courses_heading ac_mg_leg"></div>
        <div className="my_languages_wrap">
          <div className="languages_my_details">
            <div className="card">
              <div className="content_languages_details">
                <div className="icon_language_settings">
                  <FaRegClock size={36} />
                  <div className="">
                    {!isAuthenticated
                      ? "need login for looking stats "
                      : `${
                          sentanceHistory
                            ? sentanceHistory.stayTime.toFixed(4)
                            : ""
                        } Hours`}
                  </div>
                </div>
                <div className="icon_language_settings">
                  <LuClipboardList size={36} />
                  <div className="">
                    {!isAuthenticated
                      ? "need login for looking stats "
                      : `${
                          sentanceHistory
                            ? SentanceFilter?.data[0]?.radio == "Only new" ||
                              SentanceFilter?.data[0]?.radio ==
                                "Only repetition"
                              ? sentanceHistory?.uniqueNewAndrepititionSentence
                                  ?.length == 1
                                ? sentanceHistory
                                    ?.uniqueNewAndrepititionSentence?.length +
                                  " Sentence"
                                : sentanceHistory
                                    ?.uniqueNewAndrepititionSentence?.length +
                                  " Sentences"
                              : sentanceHistory?.uniqueNewAndrepititionSentence
                                  ?.length == 1
                              ? sentanceHistory?.uniqueNewAndrepititionSentence
                                  ?.length + " Sentence"
                              : sentanceHistory?.uniqueNewAndrepititionSentence
                                  ?.length + " Sentences"
                            : ""
                        }`}
                  </div>
                </div>

                {/* /// */}
                {SentanceFilter?.data[0]?.radio === "Only repetition" ||
                SentanceFilter?.data[0]?.radio === "Only new " ? (
                  <div className="icon_language_settings">
                    <LuRepeat size={36} />
                    <div className="">
                      {isAuthenticated
                        ? `${
                            sentanceHistory &&
                            sentanceHistory?.sentenceRepetition +
                              sentanceHistory?.sentenceNewRepetition
                          } Repetition${
                            sentanceHistory &&
                            sentanceHistory?.sentenceRepetition?.length +
                              sentanceHistory?.sentenceNewRepetition ==
                              1
                              ? ""
                              : "s"
                          } `
                        : "need login for looking stats"}
                    </div>
                  </div>
                ) : (
                  <div className="icon_language_settings">
                    {<LuRepeat size={36} />}
                    <div className="">
                      {!isAuthenticated
                        ? "need login for looking stats"
                        : SentanceFilter?.data[0]?.radio ===
                            "Only new and repetition" ||
                          SentanceFilter?.data[0]?.radio === "Only new"
                        ? //  ||
                          // SentanceFilter?.data[0]?.radio === "Only new"
                          sentanceHistory &&
                          sentanceHistory?.sentenceNewRepetition +
                            sentanceHistory?.sentenceRepetition ==
                            1
                          ? sentanceHistory?.sentenceNewRepetition +
                            sentanceHistory?.sentenceRepetition +
                            " Repetition"
                          : sentanceHistory?.sentenceNewRepetition +
                            sentanceHistory?.sentenceRepetition +
                            " Repetitions"
                        : ""}
                    </div>
                  </div>
                )}
                {/* /// */}
              </div>
            </div>
          </div>
          <div
            className="languages_my_details text-center"
            style={{ flexDirection: "column" }}
          >
            <button
              className="button_custom_large_rount primary pointer"
              onClick={() => (LoadSen ? "" : handleSession())}
            >
              {LoadSen ? <Loader /> : "Start Session"}
            </button>
            {scheduleCount && (
               SentanceFilter?.data[0]?.radio === "Only new and repetition" ?
              <div style={{ marginTop: "10px", fontWeight: "700" }}>
                { scheduleCount == 0 ? '' : scheduleCount}{" "}
                { SentanceFilter &&
                   ( scheduleCount && (scheduleCount == 0 || scheduleCount >= 2 )
                    ? "repetitions scheduled for today"
                    : "repetition scheduled for today"
                  )
                  }
              </div>
              : ''
            )}
          </div>
          <div className="languages_my_details dialetics_my">
            <div className="card" style={{ overflowY: "auto" }}>
              <div className="numbers_table flex-space-between">
                <div className="col1">No.</div>
                <div className="col2">Sentence</div>
                <div className="col3">Tag</div>
              </div>
              <div className="">
                {isAuthenticated ? (
                  LoadSen ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : EachCourse &&
                    EachCourse?.data?.filter(
                      (data) =>
                        data?.isLearner === true && data?.isFinalCheck === true
                    ).length <= 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                      }}
                    >
                      No Data Found
                    </div>
                  ) : (
                    EachCourse?.data
                      ?.filter(
                        (data) =>
                          data?.isLearner === true &&
                          data?.isFinalCheck === true
                      )
                      .map(
                        (v, i) =>
                          v.isLearner &&
                          v.isFinalCheck && (
                            // (sentanceHistory?.sentenceNewandRepetition)
                            // (sentanceHistory?.sentenceRepetition)
                            <>
                              <div
                                className="numbers_table_td flex-space-between"
                                style={{ width: "87%", marginLeft: "5%" }}
                              >
                                {SentanceFilter?.data[0]?.radio ===
                                "Only new and repetition"?
                                  // ? sentanceHistory
                                  //     ?.lengthNewAndrepititionSentence
                                  //     ?.length === i && (
                                  i === 0 && (
                                      <>
                                        <div className="pointer_left_abs">
                                          <FaHandPointRight size={28} />
                                        </div>
                                      </>
                                    )
                                  : SentanceFilter?.data[0]?.radio ===
                                    "Only new"
                                  ? i === 0 && (
                                      <>
                                        <div className="pointer_left_abs">
                                          <FaHandPointRight size={28} />
                                        </div>
                                      </>
                                    )
                                  : ""}

                                <div className="col1">{i + 1}</div>
                                <div
                                  style={{
                                    overflowY: "auto",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    padding: "4px",
                                  }}
                                  className="col2"
                                >
                                  {
                                    <>
                                      <div
                                        // style={{border:'solid 1px red'}}
                                        className="pointer"
                                        onClick={() => {
                                          dispatch(IsNext_Reducer(false));
                                          navigate("/view/sentence", {
                                            state: {
                                              v,
                                              index: i,
                                              alldata:
                                                EachCourseSentance &&
                                                EachCourseSentance,
                                              Id: v._id,
                                              language:
                                                location?.state?.language,
                                            },
                                          });
                                        }}
                                      >
                                        {SentanceFilter?.data[0]?.radio ===
                                        "Only new and repetition" ? (
                                          // i >
                                          // sentanceHistory
                                          //   ?.lengthNewAndrepititionSentence
                                          //   ?.length 
                                          i !== 0
                                            ? (
                                            <div
                                              
                                              onClick={(e) => { 
                                                i != 0 && e.stopPropagation();
                                                showToast(
                                                  "Follow the Sequence",
                                                  "info"
                                                );
                                              }}
                                              className={
                                                // i === 0 ? "" :
                                                 `slash_new`
                                              }
                                            >
                                              {v?.sourceContent}
                                            </div>
                                          ) : (
                                            <div>
                                              {v?.sourceContent}
                                            </div>
                                          )
                                        ) : SentanceFilter?.data[0]?.radio ===
                                          "Only new" ? (
                                          i > 0 ? (
                                            <div
                                              onClick={(e) => {
                                                i != 0 && e.stopPropagation();
                                                showToast(
                                                  "Follow the Sequence",
                                                  "info"
                                                );
                                              }}
                                              className={
                                                i === 0 ? "" : `slash_new`
                                              }
                                            >
                                              {v?.sourceContent}
                                            </div>
                                          ) : (
                                            <div>
                                              {v?.sourceContent}
                                              </div>
                                          )
                                        ) : (
                                          <div>
                                            {v?.sourceContent}
                                            </div>
                                        )}
                                      </div>
                                    </>
                                  }
                                </div>
                                <div className="col3">{v?.tag}</div>
                              </div>
                            </>
                          )
                      )
                  )
                ) : (
                  //  location?.state &&
                  // location?.state?.item?.sentenceIds?
                  LocalSentance?.data?.sentenceIds
                    ?.filter(
                      (data) =>
                        data?.isLearner == true && data?.isFinalCheck == true
                    )
                    .map(
                      (v, i) =>
                        v.isLearner &&
                        v.isFinalCheck && (
                          <>
                            <div className="numbers_table_td flex-space-between">
                              {/* {
                           sentanceHistory?.sentenceRepetition  === i &&
                            <>
                              <div className="pointer_left_abs">
                                <FaHandPointRight size={28} />
                              </div>
                            </>
                          } */}
                              <div className="col1">{i + 1}</div>
                              <div
                                style={{
                                  overflowY: "auto",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  padding: "4px",
                                }}
                                className="col2"
                              >
                                {
                                  // v.done == 1 ?
                                  <>
                                    <div
                                      className="pointer"
                                      onClick={() =>
                                        navigate("/view/sentence", {
                                          state: {
                                            v,
                                            index: i,
                                            alldata:
                                              EachCourseSentanceLocal &&
                                              EachCourseSentanceLocal,
                                            Id: v._id,
                                            language: location?.state?.language,
                                          },
                                        })
                                      }
                                    >
                                      {!isAuthenticated && i > 19 ? (
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            showToast(
                                              "You Need To Login",
                                              "info"
                                            );
                                          }}
                                          className="slash_new"
                                        >
                                          {v.sourceContent}
                                        </div>
                                      ) : (
                                        <div>{v.sourceContent}</div>
                                      )}
                                    </div>
                                  </>
                                }
                              </div>
                              <div className="col3">{v.tag}</div>
                            </div>
                          </>
                        )
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnBoard;
