import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import {
  AiFillCheckCircle,
  AiFillPlusCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { GetAllCources } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { FatchApi } from "../../utils/FatchApi";
import { UserOwnCourse_Reducer } from "../../lib/Redux/slices/UserOwnCourses";
import UserOwnDialects, {
  UserOwnDialects_Reducer,
} from "../../lib/Redux/slices/UserOwnDialects";
import Loader from "../../base-components/Loader/Loader";
import useToast from "../../hooks/useToast";
import { GetEachCourse } from "../../lib/Redux/slices/EachCourse";
import { IsDialect } from "../../lib/Redux/slices/IsDialectCondion";
import { CourseId } from "../../lib/Redux/slices/CourseId";
import { LocalSentance_Reducer } from "../../lib/Redux/slices/LocalSentance";
import { MdCancel } from "react-icons/md";
import { GetCourse } from "../../lib/Redux/slices/Course";

const my_languages = [
  { source_lang: "EN", target_lang: "DE", id: 1, image: "lang_dummy.png" },
  { source_lang: "EN", target_lang: "CY", id: 2, image: "lang_dummy.png" },
  { source_lang: "CY", target_lang: "UR", id: 3, image: "lang_dummy.png" },
];

function OnBoard() {
  const CoursesData = useSelector((state) => state.UserOwnCourse);
  const DialectsData = useSelector((state) => state.UserOwnDialects);
  const { isAuthenticated } = useSelector((state) => state.user);

  const showToast = useToast();

  const navigate = useNavigate();
  const [heading, setHeading] = useState("");
  const [popup, setPopup] = useState(false);
  const [courses, setAllCourses] = useState([]);
  const [CurrentCourses, setAllCurrentCourses] = useState([]);
  const [CurrentDialects, setAllCurrentDialects] = useState([]);
  const [TemCourses, setAllTemCourses] = useState([]);
  const [TemDialects, setAllTemDialects] = useState([]);
  const [dialectics, setAllDialects] = useState([]);
  const [Local, setLocal] = useState([]);
  const [showType, setShowType] = useState();
  const [Cloading, setCLoading] = useState(false);
  const [Dloading, setDLoading] = useState(false);
  const [SearchFound, setSearchFound] = useState(false);

  const dispatch = useDispatch();

  const show_popup = async (val) => {
    setHeading(val);
    setPopup(true);
  };

  const { user } = useSelector((state) => state.user);
  const [AllCourses, setAllCourse] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedDialects, setSelectedDialects] = useState([]);
  const LocalSentance = useSelector((state) => state.LocalSentance);
  const [sub, setSub] = useState(false);
  const [subD, setSubD] = useState(false);

  useEffect(() => {
    // console.log("use");
    if (!isAuthenticated) {
      handleSubmit("language");
      handleSubmit("dialect");
    } else {
      if (sub) {
        handleSubmit("language");
      }
      if (subD) {
        handleSubmit("dialect");
      }
    }
  }, [
    sub,
    subD,
    !isAuthenticated && selectedCourses,
    !isAuthenticated && selectedDialects,
  ]);

  useEffect(() => {
    localStorage.setItem("box", JSON.stringify({ box: "learn" }));
  }, []);

  const handleChange = (e, type) => {
    const searchText = e.target.value.toLowerCase();

    // console.log(CurrentCourses, "current...");

    const filterData = (items) => {
      return (
        items.courseName?.toLowerCase().includes(searchText) ||
        items?.sourceLanguage?.code?.toLowerCase().includes(searchText) ||
        items?.targetLanguage?.code?.toLowerCase().includes(searchText) ||
        items?.targetLanguage?.name?.toLowerCase().includes(searchText) ||
        items?.sourceLanguage?.name?.toLowerCase().includes(searchText)
      );
    };

    if (showType == "dialects") {
      const data = CurrentDialects.filter(filterData);

      if (searchText !== "" && searchText.length > 1) {
        if (data.length >= 1) {
          setAllDialects(data);
          setSearchFound(false);
        } else {
          setAllDialects([]);
          setSearchFound(true);
        }
      } else if (searchText === "") {
        setAllDialects(CurrentDialects);
        setSearchFound(false);
      }
    } else {
      const data = CurrentCourses.filter(filterData);

      if (searchText !== "" && searchText.length > 1) {
        if (data.length >= 1) {
          setAllCourses(data);
          setSearchFound(false);
        } else {
          setAllCourses([]);
          setSearchFound(true);
        }
      } else if (searchText === "") {
        setAllCourses(CurrentCourses);
        setSearchFound(false);
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setSelectedCourses(CoursesData.data);
      setSelectedDialects(DialectsData.data);
    }
    // dispatch(UserOwnCourse_Reducer([]));
    // dispatch(UserOwnDialects_Reducer([]))
  }, []);

  useEffect(() => {
    if (Local.length > 0) {
      // console.log(Local, "data before filter");

      const data = Local.filter((items) => {
        return items.isLearner === true && items.isFinalCheck === true;
      });

      // console.log(data, "data after filter");

      if (data.length >= 1) {
        navigate("/course/details", { state: { language: true } });
      } else {
        showToast("No Sentences Found", "info");
        // Optionally, clear the reducer if no sentences are found
        dispatch(LocalSentance_Reducer([]));
      }
    }
  }, [Local, navigate, dispatch]);

  const PickCourses = (picid, type) => {
    if (!isAuthenticated) {
      setSelectedCourses(CoursesData.data);
      const condition = CoursesData?.data.some(
        (item) => item._id === picid._id
      );
      if (condition) {
        const already = selectedCourses.filter(
          (item) => item._id !== picid._id
        );
        setSelectedCourses(already);
        // handleSubmit("language");
      } else {
        setSelectedCourses([...selectedCourses, picid]);
        // handleSubmit("language");
      }
    } else {
      setSubD(false);
      if (type == "language") {
        setSelectedCourses([...selectedCourses, picid]);
        setSub(true);
      } else {
        setSelectedCourses([...selectedCourses, picid]);
        setSub(false);
      }
    }
  };

  const PickDialects = (picid, type) => {
    if (!isAuthenticated) {
      setSelectedDialects(DialectsData?.data);
      const condition = DialectsData?.data.some(
        (item) => item._id === picid._id
      );
      if (condition) {
        const already = selectedDialects.filter(
          (item) => item._id !== picid._id
        );
        setSelectedDialects(already);
        handleSubmit("dialect");
      } else {
        setSelectedDialects([...selectedDialects, picid]);
        handleSubmit("dialect");
      }
    } else {
      setSub(false);
      if (type == "dialect") {
        setSelectedDialects([...selectedDialects, picid]);
        setSubD(true);
      } else {
        setSelectedDialects([...selectedDialects, picid]);
        setSubD(false);
      }
    }
  };

  const handleCourses = async (type) => {
    setCLoading(true);
    setDLoading(true);
    setShowType(type);

    // console.log(showType, "showtype");

    // get the course
    const courses = await FatchApi({
      user: user,
      request: "get",
      data: "",
      Api: "/api/v1/courses",
    });

    const dialects = await FatchApi({
      user: user,
      request: "get",
      data: "",
      Api: "/api/v1/dialects-courses",
    });

    setAllTemCourses(courses);
    setAllCourses(courses);
    setAllCurrentCourses(courses);
    setAllDialects(dialects);
    setAllTemDialects(dialects);
    setAllCurrentDialects(dialects);

    if (courses) {
      setCLoading(false);
    }

    if (dialectics) {
      setDLoading(false);
    }
  };

  const handleDialects = async (id, item,i) => {
    console.log(id,'id of dialects');
    dispatch(CourseId(id));
    dispatch(IsDialect(true));

    if (isAuthenticated) {
      setDiaLoad(true)
      setDiaLoadIndex(i)

      // console.log(data,'data');
      

      var obj = {
        tag: data.tags || ["insult", "classic", "slang"],
        manageType: data.radio || "Only new",
        courseId: id,
      };

      // console.log(obj, "ooojjj");

      const dialects = await FatchApi({
        user: user,
        request: "post",
        data: obj,
        Api: `/api/v1/manage-learner-filter-dia`,
      });

      console.log(dialects?.sentences , 'length of dia');

      if (dialects?.sentences?.length >= 1) {
        dispatch(GetEachCourse(dialects?.sentences));
        navigate("/course/details", { state: { language: false } });
        setDiaLoad(false)
      } else {
        showToast("No Sentence Avilable ", "info");
        setDiaLoad(false)
      }
    } else {
      if (item.sentenceIds.length <= 0) {
        showToast("No Sentence Avilable ", "info");
        // console.log(item.sentenceIds,'item...');
      } else {
        dispatch(LocalSentance_Reducer(item));
        navigate("/course/details", { state: { item, language: false } });
        dispatch(GetEachCourse(item?.sentenceIds));
      }
    }
  };

  const [courseLoad, setCourseLoad] = useState(false);
  const [diaLoad, setDiaLoad] = useState(false);

  const [courseLoadIndex, setCourseLoadIndex] = useState();
  const [diaLoadIndex, setDiaLoadIndex] = useState();

  const { data } = useSelector((state) => state.SentanceFilter);

  const handleLanguage = async (id, item,i) => {
    dispatch(CourseId(id));
    dispatch(GetCourse(item));
    dispatch(IsDialect(false));

    if (isAuthenticated) {
      setCourseLoad(true);
      setCourseLoadIndex(i)

      var obj = {
        // tag: data.tags || ["insult", "classic", "slang"],
        tag: data.tags || ["insult", "classic", "slang"],
        manageType: data.radio || "Only new and repetition",
        courseId: id,
      };

      const langauge = await FatchApi({
        user: user,
        request: "post",
        data: obj,
        Api: `/api/v1/manage-learner-filter`,
      });
      // if (langauge?.sentences?.length >= 1) {
        dispatch(GetEachCourse(langauge.sentences));
        setCourseLoad(false);
        navigate("/course/details", {
          state: { language: true, courseId: id },
        });
      // } else {
      //   setCourseLoad(false);
      //   showToast("No Sentence Avilable ", "info");
      // }
    } else {

      if (item?.sentenceIds?.length <= 0) {
        showToast("No Sentence Available ", "info");
      } else {
        // console.log(item?.sentenceIds,'sen id');
        setLocal(item?.sentenceIds);
        dispatch(LocalSentance_Reducer(item));
      }
    }
  };

  // useEffect(() => {}, [LocalSentance?.data?.sentenceIds]);

  const handleSubmit = async (val) => {
    // console.log(val, "valll");
    if (!isAuthenticated) {
      if (val === "language") {
        dispatch(UserOwnCourse_Reducer(selectedCourses));
      } else {
        dispatch(UserOwnDialects_Reducer(selectedDialects));
      }
    } else {
      const courseIds =
        val === "language"
          ? selectedCourses.map((items) => items._id)
          : selectedDialects.map((items) => items._id);

      const data = await FatchApi({
        request: "post",
        data: courseIds,
        Api:
          val === "language"
            ? "/api/v1/update-learner-courses"
            : `/api/v1/update-dialects-courses `,
      });

      if (data) {
        if (val === "language") {
          handleGetCourses();
          setSelectedCourses([]);
        } else {
          handleGetDialects();
          setSelectedDialects([]);
        }
      }
    }
  };

  const [selectedCourseLoading, setSelectedCourseLoading] = useState(false);
  const [selectedDialectsLoading, setSelectedDialectsLoading] = useState(false);

  const handleGetCourses = async () => {
    setSelectedCourseLoading(true);
    const data = await FatchApi({
      request: "get",
      data: "",
      Api: "/api/v1/learner-courses",
    });

    if (data?.length >= 1 && data != []) {
      setSelectedCourseLoading(false);
      dispatch(UserOwnCourse_Reducer(data));
    } else {
      dispatch(UserOwnCourse_Reducer([]));
      setSelectedCourseLoading(false);
    }
  };

  const handleGetDialects = async () => {
    setSelectedDialectsLoading(true);
    const data = await FatchApi({
      request: "get",
      data: "",
      Api: "/api/v1/dialects-learner-courses",
    });

    // console.log(data, "data");

    if (data?.length >= 1 && data != []) {
      setSelectedDialectsLoading(false);
      dispatch(UserOwnDialects_Reducer(data));
    } else {
      dispatch(UserOwnDialects_Reducer([]));
      setSelectedDialectsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleGetCourses();
      handleGetDialects();
    }
  }, []);

  ////

  const handleWrapperClick = (e) => {
    e.stopPropagation(); // Prevents the event from bubbling up to parent elements
    setPopup(false); // Sets the popup state to false when the main container is clicked
  };

  const handleInnerClick = (e) => {
    e.stopPropagation(); // Prevents the event from bubbling up to parent elements
  };

  const handleRemove = (e, type, item) => {
    e.stopPropagation();
    const confirmRemove = window.confirm(
      "Are you sure you want to remove this course from your list?"
    );

    if (confirmRemove) {
      if (type === "l") {
        PickCourses(item, "language");
      } else {
        PickDialects(item, "dialect");
      }
    }
  };

  return (
    <div className="container">
      <HeaderLogin />
      <div className="wrapper">
        <div className="my_languages_wrap">
          <div className="languages_my">
            <div className="card">
              <div className="heading_languages">
                <div className="">My languages</div>
                <span
                  className="pointer"
                  onClick={() => {
                    handleCourses("language");
                    show_popup("language");
                  }}
                >
                  <AiFillPlusCircle />
                </span>
              </div>

              <div className="content_languages">
                {selectedCourseLoading ? (
                  <Loader />
                ) : (
                  CoursesData?.data?.map((v, i) => (
                    <>
                      <div style={{ position: "relative", padding: "12px"}}>
                        {courseLoadIndex == i && courseLoad ? (
                          <>
                            <span
                              style={{
                                position: "absolute",
                                top: "47%",
                                left: "45%",
                                color: "white",
                                zIndex:200,
                              }}
                            >
                              <Loader />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        <div
                          // style={{border:'solid 1px red'}}
                          className="lang_my_selection pointer"
                          onClick={() => handleLanguage(v._id, v,i)}
                        >
                          <div
                            className="image_lang"
                            style={{ position: "relative" }}
                          >
                            {courseLoadIndex == i && courseLoad && (
                              <div
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  backgroundColor: "black",
                                  opacity: "0.5",
                                  borderRadius: "10px",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                {" "}
                              </div>
                            )}
                            <img src={v.image} />
                          </div>
                          <div className="title_">
                            {/* <div>
                              {v?.sourceLanguage?.code} <AiOutlineArrowRight />{" "}
                              {v?.targetLanguage?.code}
                            </div> */}
                            <div>{v?.courseName}</div>
                          </div>
                          <div
                            onClick={(e) => handleRemove(e, "l", v)}
                            style={{
                              top: "0px",
                              left: "92%",
                              position: "absolute",
                              color: "white",
                              backgroundColor: "#545454",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                          >
                            <MdCancel style={{ fontSize: "16px" }} />
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>


            </div>
          </div>
          <div className="languages_my dialetics_my">
            <div className="card">
              <div className="heading_languages">
                <div className="">My dialects</div>
                <span
                  className="pointer"
                  onClick={() => {
                    handleCourses("dialects");
                    show_popup("dialects");
                  }}
                >
                  <AiFillPlusCircle />
                </span>
              </div>

              <div className="content_languages">
                {selectedDialectsLoading ? (
                  <Loader />
                ) : (
                  DialectsData?.data?.map((v, i) => (
                    <>
                      <div style={{ position: "relative", padding: "12px" }}>
                      {diaLoadIndex == i && diaLoad ? (
                          <>
                            <span
                              style={{
                                position: "absolute",
                                top: "47%",
                                left: "45%",
                                color: "white",
                                zIndex:200,
                              }}
                            >
                              <Loader />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        <div
                          className="lang_my_selection pointer"
                          onClick={() => handleDialects(v._id, v,i)}
                        >
                          <div className="image_lang" style={{ position: "relative" }} >
                        
                            {diaLoadIndex == i && diaLoad && (
                              <div
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  backgroundColor: "black",
                                  opacity: "0.5",
                                  borderRadius: "10px",
                                }}
                              >
                                {" "}
                              </div>
                            )}
                          <img src={v.image} />
                          </div>
                          {/* <div className="title_">
                            {v?.sourceLanguage?.code} <AiOutlineArrowRight />{" "}
                            {v?.targetLanguage?.name}
                          </div> */}
                          <div className="title_">{v?.courseName}</div>
                        </div>
                        <div
                          onClick={(e) => handleRemove(e, "d", v)}
                          style={{
                            top: "0px",
                            left: "92%",
                            position: "absolute",
                            color: "white",
                            backgroundColor: "#545454",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "24px",
                            width: "24px",
                            borderRadius: "50%",
                          }}
                        >
                          <MdCancel style={{ fontSize: "16px" }} />
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        {popup && (
          <div className="outer_wrapper">
            <div
              className="inner_wrapper_bottom"
              onClick={handleWrapperClick}
              // style={{ border: "solid 1px red" }}
            >
              {/* Your content here */}
              <div className="white_wrapper wd100" onClick={handleInnerClick}>
                <div className="heading_languages">
                  <div className="">Choose {heading}</div>
                  <span className="pointer" onClick={() => setPopup(false)}>
                    <IoCloseCircleOutline />
                  </span>
                </div>

                <div className="choose_lang_">
                  {showType === "language" ? (
                    Cloading ? (
                      <Loader />
                    ) : SearchFound ? (
                      "No Courses found by your search ..."
                    ) : (
                      courses?.map((course) => {
                        const some = selectedCourses.some(
                          (item) => item._id === course._id
                        );

                        return (
                          <div style={{position:'relative'}} >    
                          {
                              CoursesData.data?.some(
                                (items) => items._id === course._id
                              ) ?
                          <div style={{position:'absolute',height:'100%',width:'100%',backgroundColor:'black',opacity:'0',zIndex:'800'}} >abc</div>
                          :''
                          }
                          <div
                            style={{position:'relative'}}
                            key={course?._id}
                            className="lang_my_selection relative pointer"
                            onClick={() => PickCourses(course)}
                          >
                            {!isAuthenticated ? (
                              selectedCourses?.some(
                                (items) => items._id === course._id
                              ) ? (
                                <div className="check_green">
                                  <AiFillCheckCircle />
                                </div>
                              ) : (
                                ""
                              )
                            ) : !selectedCourses.some(
                                (items) => items._id === course._id
                              ) &&
                              CoursesData.data?.some(
                                (items) => items._id === course._id
                              ) ? (
                              <div className="check_green">
                                <AiFillCheckCircle />
                              </div>
                            ) : selectedCourses.some(
                                (items) => items._id === course._id
                              ) &&
                              !CoursesData.data?.some(
                                (items) => items._id === course._id
                              ) ? (
                              <div className="check_green">
                                <AiFillCheckCircle />
                              </div>
                            ) : selectedCourses.some(
                                (items) => items._id === course._id
                              ) &&
                              CoursesData.data?.some(
                                (items) => items._id === course._id
                              ) ? (
                              " "
                            ) : (
                              ""
                            )}

                            <div className="image_lang">
                              <img
                                className="ChoiceImg"
                                src={course?.image}
                                alt="course"
                              />
                            </div>
                            <div
                              className="title_LangTitle"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%", // Corrected typo in width property
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "max-content",
                                }}
                              >
                                {/* {course?.sourceLanguage?.code}{" "}
                                <AiOutlineArrowRight />{" "}
                                {course?.targetLanguage?.code} */}
                                {course?.courseName}
                              </div>
                            </div>
                          </div>
                          </div>
                        );
                      })
                    )
                  ) : Dloading ? (
                    <Loader />
                  ) : SearchFound ? (
                    "No Dialects Found in Your Search ..."
                  ) : (
                    dialectics?.map((course) => {
                      const some = selectedDialects.some(
                        (item) => item._id === course._id
                      );

                      return (
                        <div
                          key={course?._id}
                          className="lang_my_selection relative pointer"
                          onClick={() => PickDialects(course)}
                        >
                          {!isAuthenticated ? (
                            selectedDialects?.some(
                              (items) => items._id === course._id
                            ) ? (
                              <div className="check_green">
                                <AiFillCheckCircle />
                              </div>
                            ) : (
                              ""
                            )
                          ) : !selectedDialects.some(
                              (items) => items._id === course._id
                            ) &&
                            DialectsData.data?.some(
                              (items) => items._id === course._id
                            ) ? (
                            <div className="check_green">
                              <AiFillCheckCircle />
                            </div>
                          ) : selectedDialects.some(
                              (items) => items._id === course._id
                            ) &&
                            !DialectsData.data?.some(
                              (items) => items._id === course._id
                            ) ? (
                            <div className="check_green">
                              <AiFillCheckCircle />
                            </div>
                          ) : selectedDialects.some(
                              (items) => items._id === course._id
                            ) &&
                            DialectsData.data?.some(
                              (items) => items._id === course._id
                            ) ? (
                            " "
                          ) : (
                            ""
                          )}
                          <div className="image_lang">
                            <img
                              className="ChoiceImg"
                              src={course?.image}
                              alt="course"
                            />
                          </div>
                          <div
                            className="title_LangTitle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%", // Corrected typo in width property
                            }}
                          >
                            <div
                              style={{ display: "flex", width: "max-content" }}
                            >
                              {/* {course?.sourceLanguage?.code}{" "}
                      <AiOutlineArrowRight />{" "}
                      {course?.targetLanguage?.name} */}
                              {course?.courseName}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>

                <div className="footer_languages">
                  <div className="">
                    <input
                      onChange={(e) => handleChange(e, "languages")}
                      type="text"
                      placeholder="search here..."
                      className="deposit_input"
                    />
                  </div>
                  <span onClick={() => setPopup(false)}>
                    <button
                      onClick={() => handleSubmit(heading)}
                      className="button_custom_round primary"
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OnBoard;
